import React, { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Helmet } from 'react-helmet';
import { useSwipeable } from "react-swipeable";

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend);

import { API_URL, WS_URL, LLM_URL } from '../shared/config';
import { useParams } from 'react-router-dom';
import { arrowRotation, cacheBustParam, calculateDewPoint, degreeToDirection, formatHumidity, formatNumber, formatTemperature, tempConvert, timeSinceFormatter } from '../shared/utils';
import { MaxFeelsLike } from './shared/FeelsLike';
import { Header } from './shared/Header';
import { getSavedSensors } from '../shared/saved-sensors';

import AirSvg from '../static/air-icon.svg';
import { Toggle } from './shared/Toggle';
import { FeelsLikeContainer } from './shared/ReadingContainers';


type Reading = {
  battery: number | null,
  eventrain: number | null,
  total_rain: number | null,
  feels_like: number | null,
  humidity: number,
  id: number,
  max_temp: number | null,
  max_wind: number | null,
  max_windgust: number | null,
  min_temp: number | null,
  min_wind: number | null,
  min_windgust: number | null,
  solar_radiation: number | null,
  temp_type: string,
  temperature: number,
  timestamp: string,
  winddir: number | null,
  windgust: number | null,
  windspeed: number | null,
}

const fetchSensor = async (sensorName: string, setSensor: Function, setReadings: Function, sendJsonMessage: Function, sensorId?: number) => {
  let urlString = '';
  if (typeof sensorId !== 'undefined') {
    urlString = `${API_URL}/sensor-details?sensor_id=${sensorId}&${cacheBustParam()}`;
  } else {
    urlString = `${API_URL}/sensor-details?name=${sensorName}&${cacheBustParam()}`;
  }
  const response = await fetch(urlString);
  const sensor = await response.json();
  setSensor({ ...sensor });

  if (typeof sensorId === 'undefined') {
    sensorId = sensor['id'];
  }

  sendJsonMessage({ "sensors": [`${sensorId}`,] });

  fetchReadings(sensorId!, setReadings);
};

const fetchReadings = async (sensorId: number, setCurrentReading: Function) => {
  if (typeof sensorId === 'number') {
    const response = await fetch(`${API_URL}/latest-readings?sensors=${sensorId}&${cacheBustParam()}`);
    const currentReading = await response.json();

    setCurrentReading({ ...currentReading[0] });
  }

};

const fetchLlm = async (sensorName: string, setGenAiText: Function, setLlmPending: Function) => {
  setLlmPending(true);
  const response = await fetch(`${LLM_URL}/generate/${sensorName}&${cacheBustParam()}`);
  console.log(response);
  const message = await response.json();
  console.log(message);
  setGenAiText(message.message);
  setLlmPending(false);

}

const fetchHistorical = async (sensorId: number, duration: string, setReadings: Function,): Promise<void> => {
  if (typeof sensorId === 'number') {
    const response = await fetch(`${API_URL}/fetch-historical?sensor_id=${sensorId}&days=${duration}&${cacheBustParam()}`);
    const historicalReadings: Reading[] = await response.json();

    if (Array.isArray(historicalReadings)) {
      setReadings([...historicalReadings]);
    }
  }

};

const tempChartData = (readings: Array<Reading>): any => {
  if (typeof readings === 'undefined' || readings.length === 0) return { dataset: [{ data: [] }] };
  const feelsLikeTemps = readings.map((reading) => {
    if (reading.feels_like) {
      const convertedTemp = tempConvert(reading.temperature, reading.temp_type);
      const feelsLike = tempConvert(reading.feels_like, reading.temp_type);
      if (feelsLike === convertedTemp) {
        return null;
      }
      return feelsLike;
    }
    return null;


  });
  return {
    labels: [...readings.map(reading => new Date(`${reading.timestamp}Z`).getTime())],
    datasets: [{
      label: 'Temperature',
      data: [...readings.map((reading) => tempConvert(reading.temperature, reading.temp_type))],
      fill: false,
      borderColor: '#248ADB',
      pointRadius: 0,
      hoverRadius: 5,
      yAxisID: 'y',
    },
    {
      label: 'Feels like',
      data: feelsLikeTemps,
      fill: false,
      borderColor: '#DB7524',
      pointRadius: 0,
      hoverRadius: 5,
      yAxisID: 'y',
    },],
  }
};

const humidityChartData = (readings: Array<Reading>): any => {
  if (typeof readings === 'undefined' || readings.length === 0) return { dataset: [{ data: [] }] };
  return {
    labels: [...readings.map(reading => new Date(`${reading.timestamp}Z`).getTime())],
    datasets: [{
      label: 'Humidity',
      data: [...readings.map((reading) => reading.humidity)],
      fill: false,
      borderColor: '#44B6BB',
      pointRadius: 0,
      hoverRadius: 5,
      yAxisID: 'y',
    },
    {
      label: 'Dew Point',
      data: [...readings.map((reading) => calculateDewPoint(tempConvert(reading.temperature, reading.temp_type), reading.humidity))],
      fill: false,
      borderColor: '#CD3263',
      pointRadius: 0,
      hoverRadius: 5,
      yAxisID: 'y1',
    },],
  }
};


const rainChartData = (readings: Array<Reading>): any => {
  if (typeof readings === 'undefined' || readings.length === 0) return { dataset: [{ data: [] }] };
  let rainCount = null;
  let rainRunningTotal = 0;
  const totalRain = readings.map(reading => {
    if (rainCount === null) {
      rainCount = reading.total_rain;
    }
    const rainDiff = reading.total_rain - rainCount;
    rainCount += rainDiff;
    rainRunningTotal += rainDiff;
    return rainRunningTotal;
  })
  return {
    labels: [...readings.map(reading => new Date(`${reading.timestamp}Z`).getTime())],
    datasets: [
      {
        label: 'Total Rain',
        data: totalRain,
        fill: false,
        borderColor: '#206BDF',
        pointRadius: 0,
        hoverRadius: 5,
        yAxisID: 'y1',
      },
      {
        label: 'Event Rain',
        data: [...readings.map((reading) => reading.eventrain)],
        fill: false,
        borderColor: '#25DAD4',
        pointRadius: 0,
        hoverRadius: 5,
        yAxisID: 'y',
      },
    ],
  }
};

const solarChartData = (readings: Array<Reading>): any => {
  if (typeof readings === 'undefined' || readings.length === 0) return { dataset: [{ data: [] }] };

  return {
    labels: [...readings.map(reading => new Date(`${reading.timestamp}Z`).getTime())],
    datasets: [{
      label: 'Solar Radiation',
      data: [...readings.map((reading) => reading.solar_radiation)],
      fill: false,
      borderColor: '#CD3263',
      tension: 0.1,
      pointRadius: 1,
      hoverRadius: 5,
      yAxisID: 'y',
      showLine: false,
    }, {
      label: 'UV Index',
      data: [...readings.map((reading) => reading.uv)],
      fill: false,
      borderColor: '#32CD9C',
      tension: 0.1,
      hoverRadius: 5,
      yAxisID: 'y1',
      pointRadius: 0,
    },
    ],
  }
};

const windChartData = (readings: Array<Reading>): any => {
  if (typeof readings === 'undefined' || readings.length === 0) return { dataset: [{ data: [] }] };

  return {
    labels: [...readings.map(reading => new Date(`${reading.timestamp}Z`).getTime())],
    datasets: [{
      label: 'Wind Speed',
      data: [...readings.map((reading) => reading.windspeed)],
      fill: false,
      borderColor: 'rgba(120, 187, 68, .7)',
      tension: 0,
      pointRadius: 1,
      hoverRadius: 5,
      yAxisID: 'y',
      showLine: false,
    }, {
      label: 'Wind Gust',
      data: [...readings.map((reading) => reading.windgust)],
      fill: false,
      borderColor: 'rgba(120, 68, 187, .8)',
      tension: 0,
      pointRadius: 1,
      hoverRadius: 5,
      yAxisID: 'y1',
      showLine: false,
    },
    ],
  }
};

const tempChartOptions = {

  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'index',
    intersect: false
  },
  animation: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },

  scales: {
    x: {
      type: 'time',
    }
  }
};

const expandedTempChartOptions = {
  ...tempChartOptions,
  scales: {
    x: {
      type: 'time',
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};

const humidityChartOptions = {
  ...expandedTempChartOptions,
};

const rainChartOptions = {
  ...tempChartOptions,
  scales: {
    x: {
      type: 'time',
    },
    y: {
      beginAtZero: true,
      position: 'left',
    },
    y1: {
      beginAtZero: true,
      position: 'right',
    }
  }
}

const solarChartOptions = {
  ...tempChartOptions,
  scales: {
    x: {
      type: 'time',
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};



const SensorDetailContainer = (props) => {
  const { sensorName } = useParams();

  if (sensorName === undefined) {
    return <div>Not found</div>;
  }

  const [sensor, setSensor] = useState({});
  const [currentReading, setCurrentReading] = useState({});

  const [tabView, setTabView] = useState('1');

  const [isGenAiEnabled, setGenAiEnabled] = useState(false);
  const [isGenAiPending, setGenAiPending] = useState(false);
  const [genAiText, setGenAiText] = useState("");

  const [sevenDayReadings, setSevenDayReadings] = useState([]);
  let sevenDayInterval: number;
  const [thirtyDayReadings, setThirtyDayReadings] = useState([]);
  let thrityDayInterval: number;
  const [sixHourReadings, setSixHourReadings] = useState([]);
  let sixHourInterval: number;
  const [oneYearReadings, setOneYearReadings] = useState([]);
  let oneYearInterval: number;
  const [oneHourReadings, setOneHourReadings] = useState([]);
  let oneHourInterval: number;


  const getHistoricalData = () => {

    switch (tabView) {
      case '1':
        return sensor.history;
      case '7':
        return sevenDayReadings;
      case '30':
        return thirtyDayReadings;
      case '0.25':
        return sixHourReadings;
      case '365':
        return oneYearReadings;
      case '0.1':
        return oneHourReadings;
    }
  }

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => sendJsonMessage({ "sensors": [] }),
    //Will attempt to reconnect on all close events, such as server shutting down
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      // Only set if data is newer.
      if (data['id'] === sensor['id'] && data['timestamp'] > currentReading['timestamp']) {
        if (isGenAiEnabled) {
          enableAndFetchLlm();
        }
        setCurrentReading({ ...data });
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    fetchSensor(sensorName, setSensor, setCurrentReading, sendJsonMessage);
  }, []);

  const [timeSince, setTimeSince] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {

      setTimeSince(Math.round(Date.now() / 1000 - currentReading.timestamp));
    }, 1000);
    return () => clearInterval(interval);
  }, [currentReading]);

  useEffect(() => {
    // This is removed since when switching with swipe/keyboard, the endpoint is hit twice.
    // I believe this was added when sitting on an inactive tab, but not 100% sure.
    // The onWindowFocus definitely is. This might just not be needed at all.
    // fetchReadings(sensor.id, setCurrentReading);
    const onWindowFocus = () => {
      fetchReadings(sensor.id, setCurrentReading);
    };

    window.addEventListener("focus", onWindowFocus);
    return () => {
      window.removeEventListener("focus", onWindowFocus);
    };
    // setInterval(() => loadReadings(setReadings, sensorList), 15 * 1000);
  }, [sensor,]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      handleSwipe('right');
    } else if (event.key === 'ArrowRight') {
      handleSwipe('left');
    }
  }, [sensor]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, sensor]);

  const handleSwipe = (direction: string) => {
    let sensorIndex = getSavedSensors().indexOf(sensor['id']);

    if (direction === 'left') {
      sensorIndex += 1;
    } else {
      sensorIndex -= 1;
    }

    if (sensorIndex >= 0 && sensorIndex < getSavedSensors().length) {
      setSensor({});
      setCurrentReading({});

      setTabView('1');

      setSevenDayReadings([]);
      setThirtyDayReadings([]);
      setSixHourReadings([]);
      setOneYearReadings([]);
      setOneHourReadings([]);

      fetchSensor('', setSensor, setCurrentReading, sendJsonMessage, getSavedSensors()[sensorIndex]);
    }

  }

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      handleSwipe('right');
    },
    onSwipedLeft: (eventData) => {
      handleSwipe('left');
    },

    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: 250,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)

  })

  const changeTab = (tabAmount: string): void => {
    const sensorId = sensor.id;
    if (tabAmount === '7' && sevenDayReadings.length === 0) {
      fetchHistorical(sensorId, '7', setSevenDayReadings);
      if (sevenDayInterval) clearInterval(sevenDayInterval);
      sevenDayInterval = setInterval(() => {
        fetchHistorical(sensorId, '7', setSevenDayReadings);
      }, 1000 * 60 * 15);
    }
    if (tabAmount === '30' && thirtyDayReadings.length === 0) {
      fetchHistorical(sensorId, '30', setThirtyDayReadings);
      if (thrityDayInterval) clearInterval(thrityDayInterval);
      thrityDayInterval = setInterval(() => {
        fetchHistorical(sensorId, '30', setThirtyDayReadings);
      }, 1000 * 60 * 60);
    }
    if (tabAmount === '0.25' && sixHourReadings.length === 0) {
      fetchHistorical(sensorId, '0.25', setSixHourReadings);
      if (sixHourInterval) clearInterval(sixHourInterval);
      sixHourInterval = setInterval(() => {
        fetchHistorical(sensorId, '0.25', setSixHourReadings);
      }, 1000 * 60 * 5);
    }
    if (tabAmount === '365' && oneYearReadings.length === 0) {
      fetchHistorical(sensorId, '365', setOneYearReadings);
      if (oneYearInterval) clearInterval(oneYearInterval);
      oneYearInterval = setInterval(() => {
        fetchHistorical(sensorId, '365', setOneYearReadings);
      }, 1000 * 60 * 60 * 24);
    }
    if (tabAmount === '0.1' && oneHourReadings.length === 0) {
      fetchHistorical(sensorId, '0.1', setOneHourReadings);
      if (oneHourInterval) clearInterval(oneHourInterval);
      oneHourInterval = setInterval(() => {
        fetchHistorical(sensorId, '0.1', setOneHourReadings);
      }, 1000 * 60);
    }
    setTabView(tabAmount);
  };

  const enableAndFetchLlm = () => {
    setGenAiEnabled(true);
    fetchLlm(sensor.dank_name, setGenAiText, setGenAiPending,);
    return false;
  }

  return <div>
    <Helmet>
      <title>{`${sensor.name} - ${(sensor.kind == 'PWS') ? 'Local Personal Weather Station' : 'Local Temperature Sensor'}`}</title>
      <link rel="canonical" href={`https://dankweather.com/sensor/${sensorName}`} />
    </Helmet>
    <Header />
    {(typeof sensor.name === 'undefined' || typeof currentReading.temperature === 'undefined') && <div className="sensor-loading-container">
      <div className="title">
        Loading sensor data...
      </div>
      <div className="details">
        live data...{typeof sensor.name !== 'undefined' && <>done</>}
      </div>
      <div className="details">
        historical data...{typeof currentReading.temperature !== 'undefined' && <>done</>}
      </div>
    </div>}
    {typeof sensor.name !== 'undefined' && typeof currentReading.temperature !== 'undefined' && <div className="sensor-detail-container" {...swipeHandlers}>
      <div className="toggle-container">
        <Toggle id={sensor.id} />
      </div>
      <div className="page-title-container">
        <h1>{sensor.name}</h1>
        <div className="sensor-subheader">
          {sensor.location} {sensor.kind} from {sensor.username}
        </div>
        <div className="sensor-updated">
          Last Update: {timeSinceFormatter(timeSince)}
        </div>
      </div>
      <div className='top-containers'>
        <div className="top-left-container">
          <div className="temp-box">
            <div className="current-temp">
              {formatTemperature(currentReading.temperature, currentReading.temp_type, false)}
              <span className="temp-type">°F</span>
            </div>
            <div className="high-low-temps">
              <div className="high">
                <span className="high-low-icon">▲</span>{formatTemperature(sensor.max_temp, currentReading.temp_type, false)}°
              </div>
              <div className="low">
                <span className="high-low-icon">▼</span>{formatTemperature(sensor.min_temp, currentReading.temp_type, false)}°
              </div>
            </div>
          </div>
          <div className="feels-like-container">
            <FeelsLikeContainer feelsLike={currentReading.feels_like} temperature={currentReading.temperature} tempType={currentReading.temp_type} alwaysShow={true} />
            <MaxFeelsLike readings={sensor.history} tempType={currentReading.temp_type} />
          </div>

        </div>
        <div className="top-right-corner">
          {(currentReading.windspeed !== null) &&
            <div className="wind-box">
              <div className="wind-dir">
                <img src={AirSvg} className="wind-icon" style={{ 'transform': `rotate(${arrowRotation(currentReading.winddir)}deg)` }} />
              </div>
              <div className="wind-stats">
                <div className="wind-speeds">
                  {/* <WindContainer windSpeed={currentReading.windspeed} windGust={currentReading.windgust} windDir={currentReading.winddir} hideDirection={true} /> */}
                  <div className="wind-speed-row">
                    <div className="current">{formatNumber(currentReading.windspeed)}mph</div>
                    <div className="high-low">
                      <div className="high">
                        <span className="high-low-icon">▲</span>{formatNumber(sensor.max_wind)}
                      </div>
                      {/* <div className="low">
                    <span className="high-low-icon">▼</span>{formatNumber(sensor.min_wind)}
                  </div> */}
                    </div>
                  </div>
                  <div className="wind-speed-row">{degreeToDirection(currentReading.winddir)}</div>
                  <div className="wind-speed-row">gust</div>
                  <div className="wind-speed-row">
                    <div className="current">{formatNumber(currentReading.windgust)}mph</div>
                    <div className="high-low">
                      <div className="high">
                        <span className="high-low-icon">▲</span>{formatNumber(sensor.max_windgust)}
                      </div>
                      {/* <div className="low">
                    <span className="high-low-icon">▼</span>{formatNumber(sensor.min_windgust)}
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="bottom-containers">
        <div className="bottom-left-container">
          <div className="humidity-box">
            <div className="current-humidity">
              {formatHumidity(currentReading.humidity)}
            </div>
            <div className="high-low-container">
              <div className="high">
                <span className="high-low-icon">▲</span>{formatHumidity(sensor.max_humidity)}
              </div>
              <div className="low">
                <span className="high-low-icon">▼</span>{formatHumidity(sensor.min_humidity)}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-right-container">
          {(typeof currentReading.eventrain === "number") &&
            <div className="rain-box">
              <div className="total-rain">
                {formatNumber(currentReading.eventrain, 2)}"
              </div>
            </div>
          }
        </div>
      </div>
      {!!currentReading.battery && <div className="battery-container">Battery: {formatNumber(currentReading.battery, 0)}%</div>}
      <div className="genai-container">
        {!isGenAiEnabled && <a href="#" onClick={enableAndFetchLlm}>generate text</a>}
        {!!isGenAiEnabled && <div className="genai-components">
          {!!isGenAiPending && <div className="genai-loading">...</div>}
          {!isGenAiPending && <div className="genai-content">{genAiText}</div>}
        </div>}
      </div>
      {!!sensor.history &&
        <div className="graph-container">
          <div className="history-select">
            <a onClick={() => changeTab('0.1')} className={`${tabView === '0.1' ? 'selected' : ''}`}>1 hour</a>
            <a onClick={() => changeTab('0.25')} className={`${tabView === '0.25' ? 'selected' : ''}`}>6 hours</a>
            <a onClick={() => changeTab('1')} className={`${tabView === '1' ? 'selected' : ''}`}>24 hours</a>
            <a onClick={() => changeTab('7')} className={`${tabView === '7' ? 'selected' : ''}`}>7 days</a>
            <a onClick={() => changeTab('30')} className={`${tabView === '30' ? 'selected' : ''}`}>30 days</a>
            <a onClick={() => changeTab('365')} className={`${tabView === '365' ? 'selected' : ''}`}>all</a>
          </div>
          {!!getHistoricalData() && getHistoricalData().length > 0 && <>
            <div className="temp-graph">
              <Line data={tempChartData(getHistoricalData())} options={tempChartOptions} style={{ height: 500 }} />
            </div>
            <div className="humidity-graph">
              <Line data={humidityChartData(getHistoricalData())} options={humidityChartOptions} style={{ height: 500 }} />
            </div>
            {getHistoricalData()[0]?.total_rain !== null && <div className="rain-graph">
              <Line data={rainChartData(getHistoricalData())} options={rainChartOptions} style={{ height: 500 }} />
            </div>}
            {getHistoricalData()[0]?.solar_radiation !== null && <div className="solar-graph">
              <Line data={solarChartData(getHistoricalData())} options={solarChartOptions} style={{ height: 500 }} />
            </div>}
            {getHistoricalData()[0]?.windspeed !== null && <div className="wind-graph">
              <Line data={windChartData(getHistoricalData())} options={solarChartOptions} style={{ height: 500 }} />
            </div>}
          </>}
        </div>}
    </div>}
  </div>;
};


export { SensorDetailContainer };